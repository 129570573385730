<template>      
	<form @submit.prevent="checkForm" class="mb-4">
		<div class="row">
			<div class="col-12" v-if="form_message !== ''">
				<ErrorAlert :messageI18n="form_message" />
			</div>

			<div class="col-12">
				<div class="mb-2">
					<label class="mb-1" for="tiers_id">{{ $t('tiers.billing_entity') }} *</label>
					<e-select
						v-model="default_form.invoicedetails_author"
						id="entity_id"
						track-by="tiers_id"
						label="tiers_rs"
						:placeholder="labelTitleEntity"
						:selectedLabel="selectedLabel"
						:options="entity"
						:searchable="true"
						:allow-empty="false"
						:loading="isLoadingEntity"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</div>

			<div class="col-12">
				<div class="mb-2">
					<label class="mb-1" for="horse_id">{{ $t('horse.horse') }}</label>
					<e-select
						v-model="default_form.invoicedetails_horse"
						track-by="name"
						label="name"
						:placeholder="labelTitleHorse"
						:selectedLabel="selectedLabel"
						:options="horses"
						:searchable="true"
						:allow-empty="false"
						:loading="isLoadingHorse"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</div>
	

			<template v-if="default_form.invoicedetails_author ">
				<div class="col-12" v-if="!isAddArticle">
					<div class="mb-2">
						<label class="mb-1" for="search_article">{{ $t('invoice.article_a_facturer') }}</label>
						<SearchArticles 
							:entity_id="default_form.invoicedetails_author.tiers_id"
							:preselected.sync="default_form.invoicedetails_articles"
							:articles_selected.sync="on_article_selected"
						></SearchArticles>
						
					</div>
				</div>
				<div class="col-12" v-if="!isAddArticle">
					<div class="mb-2">
						<label class="mb-1" for="articles_ht">{{ $t('compta.form.tarif_ht') }} *</label>
						<PriceInput v-model="default_form.invoicedetails_htunit_before_discount" />
					</div>
				</div>
				
				<div class="col-12" v-if="!isAddArticle">
					<hr>
					<button class="btn btn-secondary btn-block mb-2 bnt-block" @click.prevent="addArticle"><font-awesome-icon :icon="['far', 'layer-plus']" /> {{ $t('invoice.creer_article') }}</button>
				</div>
				<div class="col-12" v-if="isAddArticle">
					<div class="row">
						<div class="col-12">
						    <div class="mb-2">
						        <label for="articles_articlestype" class="col-form-label mb-1">{{ $t("compta.form.articles_type") }} *</label>
						         <e-select
						            v-model="articles.articles_articlestype"
						            id="articles_articlestype"
						            track-by="articlestype_id"
						            label="articlestype_label"
						            :placeholder="labelTitleArticlesType"
						            :deselectLabel="deselectLabel"
						            :selectedLabel="selectedLabel"
						            :selectLabel="enter_to_select"
						            :options="articles_type"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': error && error.indexOf('Erreur articles type') > -1 }"
						        >
						    	</e-select>
						    </div>
						</div>
					</div>
					<template v-if="articles.articles_articlestype != null">
						<div class="row">
							<div class="col-6">
								<div class="mb-2">
									<label class="mb-1" for="articles_label">{{ $t('compta.form.label_article') }} *</label>
									<input type="text" class="form-control" v-model="articles.articles_label" required>
								</div>
							</div>
							<div class="col-6">
								<div class="mb-2">
									<label class="mb-1" for="articles_code">{{ $t('compta.form.code_article') }} *</label>
									<input type="text" class="form-control" v-model="articles.articles_code" required>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-6">
								<div class="mb-2">
									<label class="mb-1" for="articles_ht">{{ $t('compta.form.tarif_ht') }} *</label>
									<input type="text" class="form-control" v-model="articles.articles_ht" required>
								</div>
							</div>
							<div class="col-6">
								<div class="mb-2">
									<label class="mb-1" for="articles_vat">{{ $t('compta.form.vat') }} *</label>

									<e-select
										v-model="articles.articles_vat"
										id="search_vat"
										track-by="value"
										label="name"
										:placeholder="labelTitleVat"
										:selectedLabel="selectedLabel"
										:options="vat"
										:searchable="true"
										:allow-empty="false"
										:loading="isLoadingVat"
										:show-labels="false"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
										<template slot="noOptions">{{ $t('global.list_empty') }}</template>
									</e-select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-12 col-sm-6">
								<div class="mb-2">
									<label for="articles_accountingaccount" class="col-form-label mb-1">{{ $t("compta.form.compte") }} *</label>
									<e-select
									    v-model="articles.articles_accountingaccount"
									    id="articles_accountingaccountfdvex"
									    track-by="id"
									    label="name"
									    :placeholder="labelTitleCompteComptable"
									    :selectedLabel="selectedLabel"
									    :selectLabel="enter_to_select"
									    :options="comptes_comptables"
									    :searchable="true"
									    :show-labels="false"
									    :allow-empty="false"
									    :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable') > -1 }"
									>
									    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
									    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
									</e-select>
								</div>
							</div>

							<div class="col-12 col-sm-6">
							    <div class="mb-2">
							        <label for="articles_vataccount" class="col-form-label mb-1">{{ $t("compta.form.vat_compte_comptable") }} *</label>
							        <e-select
							            v-model="articles.articles_vataccount"
							            id="articles_vataccount"
							            track-by="id"
							            label="name"
							            :placeholder="labelTitleCompteComptable"
							            :deselectLabel="deselectLabel"
							            :selectedLabel="selectedLabel"
							            :selectLabel="enter_to_select"
							            :options="comptes_comptables_vat"
							            :searchable="true"
							            :show-labels="false"
							            :allow-empty="false"
							            :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable') > -1 }"
							        >
							            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
							        </e-select>
							        
							    </div>
							</div>

							<div class="col-12 col-sm-6">
							    <div class="mb-2">
							        <label for="articles_accountingaccountfdvxp" class="col-form-label mb-1">{{ $t("compta.form.articles_accountingaccountfdvxp") }} *</label>
							        <e-select
							            v-model="articles.articles_accountingaccountfdvxp"
							            id="articles_accountingaccountfdvxp"
							            track-by="id"
							            label="name"
							            :placeholder="labelTitleCompteComptable"
							            :deselectLabel="deselectLabel"
							            :selectedLabel="selectedLabel"
							            :selectLabel="enter_to_select"
							            :options="comptes_comptables"
							            :searchable="true"
							            :show-labels="false"
							            :allow-empty="false"
							            :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable fdvxp') > -1 }"
							        >
							            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
							        </e-select>
							        <!-- <small id="passwordHelpBlock" class="form-text text-muted">
							            {{ $t('compta.form.articles_accountingaccountfdvxp_text') }}
							        </small> -->
							    </div>
							</div>


							<div class="col-12 col-sm-6">
							    <div class="mb-2">
							        <label for="articles_vataccountfdvxp" class="col-form-label mb-1">{{ $t("compta.form.articles_vataccountfdvxp") }} *</label>
							        <e-select
							            v-model="articles.articles_vataccountfdvxp"
							            id="articles_vataccountfdvxp"
							            track-by="id"
							            label="name"
							            :placeholder="labelTitleCompteComptable"
							            :deselectLabel="deselectLabel"
							            :selectedLabel="selectedLabel"
							            :selectLabel="enter_to_select"
							            :options="comptes_comptables_vat"
							            :searchable="true"
							            :show-labels="false"
							            :allow-empty="false"
							            :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable fdvxp') > -1 }"
							        >
							            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
							        </e-select>
							    </div>
							</div>


							<div class="col-12 col-sm-6">
							    <div class="mb-2">
							        <label for="articles_accountingaccountfdvex" class="col-form-label mb-1">{{ $t("compta.form.articles_accountingaccountfdvex") }} *</label>
							        <e-select
							            v-model="articles.articles_accountingaccountfdvex"
							            id="articles_accountingaccountfdvex"
							            track-by="id"
							            label="name"
							            :placeholder="labelTitleCompteComptable"
							            :deselectLabel="deselectLabel"
							            :selectedLabel="selectedLabel"
							            :selectLabel="enter_to_select"
							            :options="comptes_comptables"
							            :searchable="true"
							            :show-labels="false"
							            :allow-empty="false"
							            :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable fdvex') > -1 }"
							        >
							            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
							        </e-select>
							        <!-- <small id="passwordHelpBlock" class="form-text text-muted">
							            {{ $t('compta.form.articles_accountingaccountfdvex_text') }}
							        </small> -->
							    </div>
							</div>

							<div class="col-12 col-sm-6">
							    <div class="mb-2">
							        <label for="articles_vataccountfdvex" class="col-form-label mb-1">{{ $t("compta.form.articles_vataccountfdvex") }} *</label>
							        <e-select
							            v-model="articles.articles_vataccountfdvex"
							            id="articles_vataccountfdvex"
							            track-by="id"
							            label="name"
							            :placeholder="labelTitleCompteComptable"
							            :deselectLabel="deselectLabel"
							            :selectedLabel="selectedLabel"
							            :selectLabel="enter_to_select"
							            :options="comptes_comptables_vat"
							            :searchable="true"
							            :show-labels="false"
							            :allow-empty="false"
							            :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable fdvex') > -1 }"
							        >
							            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
							        </e-select>
							    </div>
							</div>
						</div>
					</template>
				</div>

				<div class="col-12">
					<b-form-checkbox id="discount_checkbox" v-model="has_discount">
						<label class="mb-2" for="discount_checkbox">{{ $t("compta.form.appliquer_remise") }}</label>
					</b-form-checkbox>
				</div>
				<div class="col-12" v-if="has_discount">
					<div class="row">
						<div class="col-6">
							<label class="mb-1" for="discount_type">{{ $t('compta.form.discount_type') }}</label>
							<e-select
								v-model="default_form.discount_type"
								id="discount_type"
								track-by="invoicediscounttype_id"
								label="invoicediscounttype_label"
								:placeholder="labelTitleDiscount"
								:selectedLabel="selectedLabel"
								:options="discount_type_formatted"
								:searchable="true"
								:allow-empty="false"
								:loading="isLoadingDiscount"
								:show-labels="false"
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ $t(option.invoicediscounttype_label) }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>
						<div class="col-6">
							<div class="mb-2">
								<label class="mb-1" for="invoicedetails_discount_value">{{ $t('compta.form.discount_value') }}</label>
								<input type="text" class="form-control" v-model="default_form.invoicedetails_discount_value">
							</div>
						</div>
					</div>
				</div>


				<!-- <div class="col-12">
					<div class="mb-2">
						<label class="mb-1" for="vat_code">{{ $t('compta.form.invoicedetails_codevat') }}</label>
						<e-select
							v-model="vat_export"
							id="vat_code"
							track-by="id"
							label="label"
							:placeholder="labelTitle"
							:selectedLabel="selectedLabel"
							:options="vat_filtred"
							:searchable="true"
							:allow-empty="true"
							:loading="isLoading"
							:show-labels="false"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
				</div> -->

				<div class="col-12" v-if="code_analytics.length > 0">
					<div class="mb-2">
						<label>{{ $t('compta.form.code_analytics') }}</label>
						<e-select
							v-model="invoice_code_analytics"
							track-by="accountingcode_label"
							label="accountingcode_label"
							:placeholder="labelTitle"
							:selectedLabel="selectedLabel"
							:options="code_analytics"
							:searchable="true"
							:allow-empty="true"
							:loading="isLoading"
							:show-labels="false"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.accountingcode_label }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
				</div>

				<div class="col-12">
					<div class="mb-2">
						<label class="mb-1" for="commentaire">{{ $t('invoice.commentaire') }}</label>
						<textarea id="commentaire" class="form-control" rows="3" v-model="default_form.invoicedetails_description"></textarea>
					</div>
				</div>
			</template>
		</div>
	</form>
</template>

<script type="text/javascript">
	import TableAction from "@/mixins/TableAction.js"
	import Accounting from "@/mixins/Accounting.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
    import Article from "@/mixins/Article.js"
    import Invoice from "@/mixins/Invoice.js"
    import Tiers from "@/mixins/Tiers.js"
    import Horse from "@/mixins/Horse.js"

	import Queue from '@/assets/js/utils/queue'


	export default {
		name: "LigneViergeSyndic",
		mixins: [Config, TableAction, Accounting, Navigation, Article, Invoice, Tiers, Horse],
		props: {
			processing: {},
			ready: {},
			horse: {},
			saillies: {}
		},
		data () {
			return {
				form_message: '',
				isAddArticle: false,
				has_discount: false,
				discount_type: [],
				code_analytics: [],
				invoice_code_analytics: {},
				on_article_selected : null,
				default_form: {
					invoicedetails_id: null,
					invoicedetails_description: null,
					invoicedetails_label: null,
					invoicedetails_ht: null,
					invoicedetails_htunit: null,
					invoicedetails_vat: 1,
					invoicedetails_horse: null,
					invoicedetails_articles: null,
					invoicedetails_actes: null,
					invoicedetails_author: null,
					invoicedetails_exportvat: null,
					invoicedetails_htunit_before_discount: null,
					invoicedetails_ht_before_discount: null,
					invoicedetails_discount_value: null,
					invoicedetails_discount_type: null,
					discount_type: null,
				},
				articles: {
					articles_label: null,
					articles_code: null,
					articles_ht: null,
					articles_vat: null,
					articles_accountingaccount: null,
					articles_accountingaccountfdvex: null,
					articles_accountingaccountfdvxp: null,
					articles_vataccount: null,
					articles_vataccountfdvex: null,
					articles_vataccountfdvxp: null,
					articles_articlestype: null,
				},

				entity_id: null,
				tiers_id: null,
				horse_id: null,
				articles_id: null,

				entity: [],
				error: [],
				horses: [],
				options_articles: [],

				isLoadingEntity: false,
				isLoadingHorse: false,
				isLoadingVat: false,
				isLoadingDiscount: false,
				isLoadingCompteComptable: false,
				isLoading: false,
				loadingQueue: new Queue(),

				cache: {
					horses: {},
					comptes_comptables: {},
					articles: {}
				},

				comptes_comptables: [],
                comptes_comptables_vat: [],
				vat: [],
				vat_filtred: [],
				vat_export: {},
				articles_type: [],
				tiers_qte: {},
				tiers_part: {},
				tiers_country: {},

				labelTitleVat: this.getTrad("vat.rechercher"),
				labelTitleCompteComptable: this.getTrad("accounting_account.rechercher"),
				labelTitleEntity: this.getTrad("tiers.rechercher_billing_entity"),
				labelTitleHorse: this.getTrad("horse.rechercher_cheval"),
				labelTitle: this.getTrad("invoice.ligne_vierge_search_article"),
				labelTitleArticlesType: this.getTrad("compta.search_articles_type"),
				labelTitleDiscount: this.getTrad("invoice.ligne_vierge_search_article"),

				labelNotFound: this.getTrad("invoice.ligne_vierge_no_article_found"),
				labelSearchPlaceholder: this.getTrad("invoice.ligne_vierge_rechercher"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				selectedLabel: this.getTrad("global.selected_label"),
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
			}
		},
		mounted() {
			this.init_component()
		},
		created () {
		},
		methods: {
			async init_component() {
                const config = this.getConfig('api_config')
                const syndic_preference = config.find(conf => conf.apiconfig_type == "SYNDIC")
                let part = "part"
                if(syndic_preference) {
                	part = syndic_preference.apiconfig_key
                }

				//GFE, on doit preselectionner la remise en pourcentage
				this.default_form.discount_type = this.discount_type[0]
					
				if(this.horse){
					this.horse_id = this.horse.horse_id
					this.default_form.invoicedetails_horse = {
						id: this.horse.horse_id,
						name: this.horse.horse_nom,
					}
					this.horses = [{
						id: this.horse.horse_id,
						name: this.horse.horse_nom,
					}]
				}

				this.tiers_qte = {}
				this.tiers_part = {}
				this.tiers_country = {}

				this.discount_type = await this.loadDiscountType()

				this.vat = []

				const vat = await this.getVat()
				for (let i = 0; i < vat.length; i++) {
					this.vat.push({
						id: vat[i].vat_id,
						name: vat[i].vat_label,
						value: vat[i].vat_value,
					})
				}

				this.saillies.forEach(saillie => {
					saillie.saillie_part.syndicpart_tiers.forEach(tier => {
						const tiers_id = tier.tierpart_tier.tiers_id
						if(!Object.prototype.hasOwnProperty.call(this.tiers_qte, tiers_id)) {
							this.tiers_qte[tiers_id] = 0
						}
						this.tiers_qte[tiers_id] += (tier.tierpart_percentage / 100)

						if(!Object.prototype.hasOwnProperty.call(this.tiers_part, tiers_id)) {
							this.tiers_part[tiers_id] = []
						}
						this.tiers_part[tiers_id].push(part+" "+saillie.saillie_number+" ("+tier.tierpart_percentage+"%)");

						if(!Object.prototype.hasOwnProperty.call(this.tiers_country, tiers_id)) {
							this.tiers_country[tiers_id] = tier.tierpart_tier.tiers_country
						}
					})
				})

				// this.code_analytics = this.getAnalyticsCode()
				this.loadAllTiers()
			},

			async addArticle(){
				this.isAddArticle = true
				let articles_type = await this.getArticlesType()
				for (let i = 0; i < articles_type.length; i++) {
					this.articles_type.push({
						articlestype_id: articles_type[i].articlestype_id,
						articlestype_label: this.getTrad(articles_type[i].articlestype_label),
						articlestype_code: articles_type[i].articlestype_code,
					})
				}
			},

			async loadAllTiers(){
				this.isLoadingEntity = true
				this.$emit('update:ready', false)

				this.entity = await this.loadTiersEntity()

				if(this.entity.length == 1) {
					let accountingplan_default = await this.loadAccountingPlanDefault()
					if(accountingplan_default) {
						this.default_form.invoicedetails_author = {
							tiers_id: accountingplan_default.tiers.tiers_id,
							tiers_rs: accountingplan_default.tiers.tiers_rs
						}
					}
				}

				this.isLoadingEntity = false
			},

			async loadAllAfterTiers() {
				await this.loadingQueue.flush()
				return this.loadingQueue.enqueue(() => {
					return this._loadAllAfterTiers(this.default_form.invoicedetails_author.tiers_id)
				})
			},

			async _loadAllAfterTiers(author_id){
				this.isLoading = true
				this.$emit('update:ready', false)

				// Gestion cache compte comptable
				if(Object.keys(this.cache.comptes_comptables).length == 0) {
					this.cache.comptes_comptables = {}
				}

				if(this.cache.comptes_comptables[author_id] == null) {
					this.loadAccountingAccountsTiers(author_id).then(comptes_comptables => {
						this.display_compte_comptable(comptes_comptables)
						this.cache.comptes_comptables[author_id] = comptes_comptables
					})
				}
				else {
					const comptes_comptables = this.cache.comptes_comptables[author_id]
					this.display_compte_comptable(comptes_comptables)
				}

				// Gestion cache article
				if(Object.keys(this.cache.articles).length == 0) {
					this.cache.articles = {}
				}


				this.options_articles = []
				this.default_form.invoicedetails_articles = null
				

				this.code_analytics = await this.getAnalyticsCode(author_id)

				this.isLoading = false
				this.$emit('update:ready', true)
			},

			display_compte_comptable(comptes_comptables){
				this.comptes_comptables_vat = []
				this.comptes_comptables = []
				for (let i = 0; i < comptes_comptables.length; i++) {
					if(comptes_comptables[i].accountingaccount_vat == null) {
						this.comptes_comptables.push({
							id: comptes_comptables[i].accountingaccount_id,
							name: comptes_comptables[i].accountingaccount_number + " ( " + comptes_comptables[i].accountingaccount_label + " )",
						})
					}
					else {
						this.comptes_comptables_vat.push({
						    name: comptes_comptables[i].accountingaccount_number + " ("+ comptes_comptables[i].accountingaccount_label +")", 
						    id: comptes_comptables[i].accountingaccount_id })
					}
				}
			},

			async loadVatCode(){
				//Je vais appeler l'api pour récuperer le code de tva à appliquer
				this.default_form.invoicedetails_codevat = await this.getVatCode(this.entity_id, this.tiers_id)
			},

			async checkForm(){

				this.$emit('update:processing', false)
				if(!this.processing) {
					// this.processing = true
					this.$emit('update:processing', true)
					this.error = []
					let error_values = []

					if(this.isAddArticle) {
						if(this.articles.articles_vat === null) {
							this.error.push("Erreur vat")
						}

						if(this.articles.articles_accountingaccount === null) {
							this.error.push("Erreur accounting account")
						}

						if(this.articles.articles_accountingaccountfdvex === null) {
							this.error.push("Erreur accounting account fdvex")
						}

						if(this.articles.articles_accountingaccountfdvxp === null) {
							this.error.push("Erreur accounting account fdvxp")
						}

						if(this.articles.articles_vataccount === null) {
							this.error.push("Erreur vat accounting account")
						}

						if(this.articles.articles_vataccountfdvex === null) {
							this.error.push("Erreur vat accounting account fdvex")
						}

						if(this.articles.articles_vataccountfdvxp === null) {
							this.error.push("Erreur vat accounting account fdvxp")
						}

						if(!this.articles.articles_label) {
							this.error.push("Erreur accounting label")
						}

						if(!this.articles.articles_code) {
							this.error.push("Erreur accounting code")
						}

						if(!this.articles.articles_ht) {
							this.error.push("Erreur accounting ht")
						}
					}
					else if(this.default_form.invoicedetails_articles == null) {
						this.error.push("Erreur no article selected")
					}

					if(this.error.length === 0 && error_values.length === 0) {
						this.form_message = ""
						let htunit = this.default_form.invoicedetails_htunit_before_discount
						let discount_value = (this.has_discount) ? this.default_form.invoicedetails_discount_value : null
						let discount_type = (this.has_discount) ? this.default_form.discount_type : null
						let vat = this.vat_export.id
						let label = this.default_form.invoicedetails_label
						let description = this.default_form.invoicedetails_description
						let vat_code = this.default_form.invoicedetails_codevat
						let export_vat = this.default_form.invoicedetails_exportvat
						let code_analytics = (this.invoice_code_analytics) ? this.invoice_code_analytics.accountingcode_label : null
						let articles_id = null
						if(this.default_form.invoicedetails_articles != null) {
							articles_id = this.default_form.invoicedetails_articles.articles_id
						}


						if(this.isAddArticle) {
							vat = this.vat.find(vat => { return vat.id == this.articles.articles_vat.id })
							const tva = parseFloat(vat.value)
							const ht = parseFloat(this.articles.articles_ht)
							let ttc = (vat.value * ht) + ht

							let result = null
							result = await this.addArticles(
								this.articles.articles_accountingaccount.id,
								this.articles.articles_vat.id,
								this.articles.articles_label,
								this.articles.articles_code,
								this.articles.articles_ht,
								ttc,
								this.articles.articles_articlestype.articlestype_id,
								this.articles.articles_accountingaccountfdvex.id,
								this.articles.articles_accountingaccountfdvxp.id,
								this.articles.articles_vataccount.id,
								this.articles.articles_vataccountfdvex.id,
								this.articles.articles_vataccountfdvxp.id
							);
							
							htunit = this.articles.articles_ht
							vat = this.articles.articles_vat.id
							label = this.articles.articles_label

							if(result) {
								articles_id = result.retour.articles_id
							}
							else {
								this.form_message = "error.LEP"
								this.$emit('update:processing', false)
								return false;
							}
						}

						let tab_promises = []

						for(let id in this.tiers_qte) {
							let paramsToSend = {};
							paramsToSend.details = []

							let codevat = await this.getVatCode(this.entity_id, id)
							if(codevat == "FDVXP" || codevat == "FDVEX" || this.tiers_country[id] != 75) {
								vat = 1
							}

							let infos = {
								author_id : this.entity_id,
								tiers_id : id,
								htunit : htunit,
								qte : this.tiers_qte[id],
								vat : vat,
								label : label,
								description : this.tiers_part[id].join(" / "),
								horse_id : this.horse_id,
								articles_id : articles_id,
								vat_code : vat_code,
								export_vat: export_vat,
								discount_value: (this.has_discount) ? discount_value : null,
								discount_type: (this.has_discount) ? discount_type : null,
								code_analytics: code_analytics,
								ht_initial: htunit * 100,
								tiershorseparttype: 0,
								tiershorsepartpart: 100,
								ca: true
							}

							paramsToSend.details.push(infos)
							paramsToSend.from_ligne_vierge = true

							tab_promises.push(this.createInvoiceDetails(paramsToSend))
						}

						await Promise.all(tab_promises)
						return true
					}
					else if(error_values.length > 0) {
						this.form_message = "formulaire.erreur_champs_mal_remplis"
						this.failureToast('formulaire.erreur_champs_mal_remplis')
					}
					else {
						this.form_message = "formulaire.erreur_champs_non_remplis"
						this.failureToast('formulaire.erreur_champs_non_remplis')
					}

					this.$emit('update:processing', false)
					return false;
				}
			},
			onArticleSelection(value) {
				if(value != undefined) {
					this.articles_id = value.articles_id
					this.default_form.invoicedetails_vat = value.vat.vat_id
					this.default_form.invoicedetails_htunit_before_discount = value.articles_ht / 100
					this.default_form.invoicedetails_label = value.articles_label
				}
			},
			fillVatFiltred(vat_id, type) {
				const vat_of_article = this.vat.find(vat => vat.id == vat_id)
				const vat_of_zero = this.vat.find(vat => vat.id == 1)

				this.vat_filtred = []

				if(type == 'produit') {
					vat_of_article.label = vat_of_article.name + " " + this.getTrad("compta.form.produit_non_exporte")
					this.vat_filtred.push(vat_of_article)
					this.vat_export = vat_of_article

					if(this.default_form.invoicedetails_codevat == "FDVXP" || this.default_form.invoicedetails_codevat == "FDVEX") {
						vat_of_zero.label = vat_of_zero.name + " " + this.getTrad("compta.form.produit_exporte")
						this.vat_filtred.push(vat_of_zero)
					}
				}

				
				if(type == 'service') {
					vat_of_article.label = vat_of_article.name + " " + this.getTrad("compta.form.service_non_exporte")
					this.vat_filtred.push(vat_of_article)
					

					if(this.default_form.invoicedetails_codevat == "FDVXP" || this.default_form.invoicedetails_codevat == "FDVEX") {
						vat_of_zero.label = vat_of_zero.name + " " + this.getTrad("compta.form.service_exporte")
						this.vat_filtred.push(vat_of_zero)
						this.vat_export = vat_of_zero
					}
					else {
						this.vat_export = vat_of_article
					}
				}
			}
		},
		computed: {
			discount_type_formatted(){
				let tab = []

				for(let i = 0; i < this.discount_type.length; i++) {
					let current = this.discount_type[i]
					current.invoicediscounttype_label = this.getTrad(current.invoicediscounttype_label)
					tab.push(current)
				}

				return tab
			}
		},
		watch:{
			'default_form.invoicedetails_author'(value) {
				if(value.tiers_id != undefined) {
					this.entity_id = value.tiers_id
					this.loadAllAfterTiers()
				}
			},
			'default_form.invoicedetails_horse'(value) {
				if(value.id != undefined) {
					this.horse_id = value.id
				}
			},
			'default_form.invoicedetails_articles'(value) {
				if(value != undefined && Object.keys(value).length > 0) {
					this.articles_id = value

					this.fillVatFiltred(value.vat.vat_id, value.articles_type.articlestype_code)
				}
			},
			'articles.articles_label'() {
				this.articles.articles_code = this.accentsTidy(this.articles.articles_label).replace(/ /g, "_")
			},
			'articles.articles_vat'(value) {
				this.fillVatFiltred(value.id, this.articles.articles_articlestype.articlestype_code)
			},
			'vat_export'(val){
				
				if((this.default_form.invoicedetails_codevat == "FDVXP" || this.default_form.invoicedetails_codevat == "FDVEX") && val.id == 1) {
					this.default_form.invoicedetails_exportvat = 1
				}
				else {
					this.default_form.invoicedetails_exportvat = 0
				}
			},
			'articles.articles_accountingaccount'(val){
			    if(this.articles.articles_accountingaccountfdvxp == null || Object.keys(this.articles.articles_accountingaccountfdvxp).length == 0) {
			        this.articles.articles_accountingaccountfdvxp = val
			    }

			    if(this.articles.articles_accountingaccountfdvex == null || Object.keys(this.articles.articles_accountingaccountfdvex).length == 0) {
			        this.articles.articles_accountingaccountfdvex = val
			    }
			},
			'articles.articles_vataccount'(val){
			    if(this.articles.articles_vataccountfdvxp == null || Object.keys(this.articles.articles_vataccountfdvxp).length == 0) {
			        this.articles.articles_vataccountfdvxp = val
			    }

			    if(this.articles.articles_vataccountfdvex == null || Object.keys(this.articles.articles_vataccountfdvex).length == 0) {
			        this.articles.articles_vataccountfdvex = val
			    }
			},
			on_article_selected(val){
				this.default_form.invoicedetails_articles = val
				this.onArticleSelection(val)
			}
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable') ,
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') ,
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			PriceInput: () => import('GroomyRoot/components/Inputs/PriceInput'),
			SearchArticles: () => import('@/components/Utils/SearchArticles')
		}
	}
</script>